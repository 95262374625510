<mat-sidenav-container>
  <mat-sidenav [fixedInViewport]="true" #sideNav>
    <div class="nav-header" *ngIf="hasProfileData">
      <div class="profile-image-wrapper">
        <div class="profile-image">
          <fancy-img [src]="profileImage || '/assets/img/ui/avatar-placeholder.png'"></fancy-img>
        </div>
        <h3>{{ 'HEADER.HI' | translate: { name: firstName } }}</h3>
      </div>
    </div>

    <div class="nav-content" (click)="sideNav.close()">
      <div class="nav-content-section" *ngIf="hasProfileData">
        <ul>
          <li>
            <a routerLink="/orders" routerLinkActive="active">{{ 'HEADER.MY_ORDERS' | translate }}</a>
            <div class="badge" *ngIf="this.notificationCount">{{ this.notificationCount }}</div>
          </li>
          <li
            ><a routerLink="/myproducts" routerLinkActive="active" *ngIf="isVendor">{{
              'HEADER.MY_ADS' | translate
            }}</a></li
          >
          <li
            ><a routerLink="/user/profile">{{ 'HEADER.EDIT_PROFILE' | translate }}</a>
            <div class="badge" *ngIf="this.profileProblems">{{ this.profileProblems }}</div></li
          >
          <li
            ><a routerLink="/users/{{ this.slug }}" *ngIf="isVendor">{{ 'HEADER.PUBLIC_PROFILE' | translate }}</a></li
          >
          <li
            ><a routerLink="/codes">{{ 'HEADER.CODES' | translate }}</a></li
          >
          <li
            ><a routerLink="/myfavorites">{{ 'HEADER.FAVORITES' | translate }}</a></li
          >
          <li
            ><a href="#" (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</a></li
          >
        </ul>
      </div>

      <div class="nav-content-section">
        <ul>
          <li
            ><a routerLink="{{ 'HEADER.RENT_OUT_LINK' | translate }}">{{ 'HEADER.RENT_OUT' | translate }}</a></li
          >
          <li
            ><a (click)="this.hyggloTranslate.openFaq('5203904-how-hygglo-works-in-short')">{{
              'HEADER.HOW_IT_WORKS' | translate
            }}</a></li
          >
          <li
            ><a (click)="this.hyggloTranslate.openFaq('5224681-how-does-the-insurance-work-when-i-rent-something')">{{
              'HEADER.INSURANCE' | translate
            }}</a></li
          >
          <li
            ><a (click)="this.hyggloTranslate.openFaq()">{{ 'HEADER.FAQ' | translate }}</a></li
          >
          <li
            ><a routerLink="/om-oss/kontakt">{{ 'HEADER.CONTACT' | translate }}</a></li
          >
        </ul>
      </div>

      <div class="nav-content-section" *ngIf="!hasProfileData">
        <ul>
          <li
            ><a (click)="login()" style="cursor:pointer;">{{ 'HEADER.LOGIN' | translate }}</a></li
          >
        </ul>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header
      [sideNav]="sideNav"
      [notificationCount]="this.notificationCount"
      [profileProblems]="this.profileProblems"
    ></app-header>
    <div class="main-content">
      <router-outlet *ngIf="loaded"></router-outlet>
    </div>
    <router-outlet name="modal"></router-outlet>
    <div #footer></div>
    <app-footer *ngIf="showFooter"></app-footer>

    <div class="spinner-wrapper" *ngIf="loaderIsShowing" style="margin:0 auto;">
      <img src="https://hygglo-web-static.imgix.net/ui/loader.gif" />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
