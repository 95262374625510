import {
  Component,
  Input,
  NgZone,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { NgRedux } from '@angular-redux/store';
import { privateAccountAPI } from '../../../webapi/private';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { HyggloTranslateService, LANGUAGE } from '../../services/translate.service';

declare const window: any;

// Events
import eventTypes from '../../../types/eventTypes';
import EventEmitter from '../../../utils/EventEmitter';

const ee = new EventEmitter();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  public sideNav: MatSidenav;
  @Input()
  public notificationCount: number;
  @Input()
  public profileProblems: number;

  public screenIsSmall: boolean = true;

  @HostBinding('class.search-expanded')
  public mobileSearchExpanded: boolean;

  @HostBinding('class.is-startpage')
  public isStartPage: boolean = false;

  @HostBinding('class.is-loaded')
  public loaded = false;

  public searchInit: boolean;
  public isLandingPage: boolean;
  public isOpaque: boolean = false;

  public unsubscribe: any;

  public currentLang: string;

  // Runtime
  public firstName: string;
  public profileImage: string;
  public slug: string;
  public isLoggedIn = false;
  public isVendor = false;
  public isOnVendorPage = false;
  public balance = 0;
  public isItXmas = false;
  public isItValentines = true;

  constructor(
    public ngRedux: NgRedux<any>,
    public ngZone: NgZone,
    public urlLocation: Location,
    public translate: HyggloTranslateService,
    private cdr: ChangeDetectorRef
  ) {
    urlLocation.onUrlChange((url, state) => {
      this.isOnVendorPage =
        url === '/hyra-ut' || url === '/lei-ut' || url === '/jata-ilmoitus' || url === '/udleje';
      this.isStartPage =
        url === '/' ||
        url === '/hyra-ut' ||
        url === '/lei-ut' ||
        url === '/jata-ilmoitus' ||
        url === '/udleje' ||
        url.substring(0, 6) === '/users' ||
        url.substring(0, 7) === '/shared';
      this.isLandingPage = url.substring(0, 7) === '/shared';
      this.loaded = true;
      if (this.mobileSearchExpanded) {
        this.closeMobileSearch();
      }
      this.cdr.detectChanges();
    });

    const currentMonth = new Date().getMonth() + 1;
    const currenDay = new Date().getDate();
    this.isItXmas = currentMonth === 12;
    this.isItValentines = currentMonth === 2 && currenDay === 14;
    this.screenIsSmall = window.innerWidth < 640;

    translate.onLangChange.subscribe((newLang: string) => {
      this.currentLang = newLang;
    });
    this.currentLang = translate.getLanguage();

    ngRedux
      .select(['account', 'response'])
      .pipe(filter((value) => !!value))
      .pipe(distinctUntilChanged())
      .subscribe((user: any) => {
        this.isLoggedIn = user ? true : false;
        this.isVendor = user.getIn(['customer', 'isLessor']);
        this.firstName = user.getIn(['customer', 'firstName']);
        this.profileImage = user.getIn(['customer', 'profileImage', 'thumbnailUrl']);
        this.balance = user.getIn(['customer', 'balance']);
        this.slug = user.getIn(['vendor', 'slug']);
        this.cdr.detectChanges();
      });

    window.addEventListener('scroll', () => {
      this.ngZone.run(() => {
        const newOpaque = window.scrollY > 30;
        if (this.isOpaque !== newOpaque) {
          this.isOpaque = newOpaque;
          this.cdr.detectChanges();
        }
      });
    });
  }

  public toggleMobileSearch() {
    if (this.mobileSearchExpanded) {
      this.mobileSearchExpanded = false;
      setTimeout(() => {
        this.searchInit = false;
      }, 300);
    } else {
      this.mobileSearchExpanded = true;
      this.searchInit = true;
    }
  }

  public closeMobileSearch() {
    this.mobileSearchExpanded = false;
    setTimeout(() => {
      this.searchInit = false;
    }, 300);
  }

  login() {
    privateAccountAPI.fetchAccount().catch(() => {
      // Resulting error is handled elsewhere
    });
  }

  public emitLogout() {
    ee.events.emit(eventTypes.LOGOUT);
  }

  public changeLanguage(langCode: string) {
    this.translate.setLanguage(langCode as LANGUAGE);
    return true;
  }
}
